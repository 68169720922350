<template>
  <b-card
    :title="editable ? 'Edita usuário' : 'Novo usuário'"
    class="text-center text-primary d-flex align-items-center"
  >
    <validation-observer ref="formUser">
      <b-row class="text-left mt-4 d-flex justify-content-center">
        <!-- username -->
        <b-col cols="8">
          <b-form-group>
            <label
              for="username"
              class="text-center font-weight-bold text-primary"
              >Nome completo :</label
            >
            <validation-provider
              #default="{ errors }"
              :custom-messages="{ required: 'Este campo é obrigatório' }"
              rules="required"
            >
              <b-form-input
                id="username"
                v-model="item.username"
                placeholder="Digite seu nome completo"
              ></b-form-input>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- email -->
        <b-col cols="8">
          <b-form-group>
            <label for="email" class="text-center font-weight-bold text-primary"
              >Email :</label
            >
            <validation-provider
              #default="{ errors }"
              :custom-messages="{ required: 'Este campo é obrigatório' }"
              rules="required"
            >
              <b-form-input
                id="email"
                v-model="item.email"
                placeholder="Digite seu email"
              ></b-form-input>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- IS ADMIN -->
      <b-row class="mx-4 d-flex justify-content-start">

        <b-col cols="4">
          <b-form-group>
            <b-form-checkbox
              switch
              :disabled="loading || item.is_blocked"
              v-model="item.is_admin"
              name="checkbox-1"
            >
              Administrador
            </b-form-checkbox>
          </b-form-group>
        </b-col>


        <b-col cols="4">
          <b-form-group>
            <b-form-checkbox
              v-if="editable && item.is_blocked"
              :disabled="loading || item.is_blocked"
              v-model="item.is_blocked"
              name="checkbox-1"
            >
              Bloqueado
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- IS MANAGER -->
      <b-row class="mx-4 d-flex justify-content-start">
        <b-col cols="4">
          <b-form-group>
            <b-form-checkbox
              switch
              :disabled="loading || item.is_blocked"
              v-model="item.is_manager"
              name="checkbox-2"
            >
              Gerente
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- IS FINANCER -->
      <b-row class="mx-4 d-flex justify-content-start">
        <b-col cols="4">
          <b-form-group>
            <b-form-checkbox
              switch
              :disabled="loading || item.is_blocked"
              v-model="item.is_transaction_manager"
              name="checkbox-2"
            >
              Financeiro
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="invoice-spacing mb-3" />
      <b-row class="justify-content-between d-flex p-2">
        <!-- back -->
        <b-button
          :disabled="loading"
          variant="warning"
          class="mr-2"
          @click="goBack"
          >Voltar
        </b-button>

        <div class="d-flex justify-content-between">
          <!-- inactivate -->
          <b-button
            v-if="editable"
            :variant="item.is_blocked ? 'success' : 'danger'"
            class="d-flex justify-content-center mr-2"
            @click="modalInactivate = true"
            :disabled="item.username.length < 1 || loading"
          >
            <span
              v-if="!loading"
              id="modal-inactivate"
              @click="item.is_blocked ? updateUser : (modalInactivate = true)"
              >{{ item.is_blocked ? 'Reativar' : 'Inativar' }}</span
            >
            <b-spinner v-if="loading"></b-spinner>
          </b-button>

          <!-- updateUser -->

          <b-button
            v-if="editable && !item.is_blocked"
            variant="primary"
            @click="updateUser"
            :disabled="item.username.length < 1 || loading"
          >
            <span v-if="!loading"> Salvar Alterações </span>
            <b-spinner class="spinner" v-if="loading"></b-spinner>
          </b-button>

          <!-- saveUser -->
          <b-button
            v-if="!editable"
            variant="primary"
            @click="saveItem"
            :disabled="item.username.length < 1 || loading"
          >
            <span v-if="!loading">Salvar</span>
            <b-spinner v-if="loading"></b-spinner>
          </b-button>
        </div>

        <!-- INACTIVE MODAL -->
        <b-modal
          title="Inativar usuário"
          no-close-on-backdrop
          hide-footer
          hide-header-close
          v-model="modalInactivate"
        >
          <b-row class="mt-1 justify-content-center d-flex text-center">
            <p>
              Deseja realmente
              <strong>{{ item.is_blocked ? 'reativar' : 'inativar' }}</strong>
              este usuário <strong>{{ item.username }}</strong
              >?
            </p>
          </b-row>

          <b-row class="justify-content-between d-flex mt-3 p-1">
            <!-- <p>Rodapé personalizado</p> -->
            <b-button variant="warning" @click="modalInactivate = false">
              Cancelar
            </b-button>
            <b-button
              variant="primary"
              @click="item.is_blocked ? activeUser() : removeUser()"
            >
              Confirmar
            </b-button>
          </b-row>
        </b-modal>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    editable: {
      Type: Boolean,
      default: false,
    },
  },
  components: {
    BCard,
    BFormInput,
    BCol,
    BRow,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BSpinner,
  },
  data: () => ({
    item: {
      username: '',
      email: '',
      user_auth_id: '',
      is_admin: false,
      is_blocked: false,
      is_workspace_admin: false,
      is_manager: false,
      is_transaction_manager: false,
      is_platform_blocked: false,
      is_platform_admin: false,
      is_workspace_blocked: false,
    },
    required,
    loading: false,
    modalInactivate: false,
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false,
    },
  }),
  created() {},
  computed: {},
  methods: {
    saveItem() {
      this.$refs.formUser.validate().then((success) => {
        if (success) {
          this.loading = true;

          this.$store
            .dispatch('createAuthUser', {
              email: this.item.email,
              username: this.item.username,
            })
            .then((resp) => {
              this.item.user_auth_id = resp.user.id;
              this.$store
                .dispatch('saveEmployee', {
                  ...this.item,
                  workspace_id: this.$store.getters.currentWorkspace.id,
                })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Usuário adicionado com sucesso.',
                      icon: 'SuccessIcon',
                      variant: 'success',
                    },
                  });
                  this.$router.push({
                    name: 'colaboradores',
                  });
                })
                .catch(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Erro ao adicionar usuário.',
                      icon: 'XIcon',
                      variant: 'danger',
                    },
                  });
                });
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao cadastrar usuário na auth',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
    goBack() {
        this.$router.go(-1);
      }
  },
};
</script>
